import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import { rhythm } from "../utils/typography"
import CodepenLogo from "../social-svgs/codepen.svg"
import Header from "./header"
import FacebookLogo from "../social-svgs/facebook.svg"
import LinkedInLogo from "../social-svgs/linkedin.svg"
import InstagramLogo from "../social-svgs/instagram.svg"
import TwitterLogo from "../social-svgs/twitter.svg"
import YouTubeLogo from "../social-svgs/youtube.svg"

class Layout extends React.Component {
  render() {
    const { location, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    const blogPath = `${__PATH_PREFIX__}/blog/`
    let link

    if(location.pathname === `/blog/` && location.pathname.length < 7) {
      link = (
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          <Header />
        </Link>
      )
    }
    else if (location.pathname === rootPath || location.pathname === blogPath) {
      link = (
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={location.pathname === blogPath ? `/blog/` : `/`}
        >
          <Header />
        </Link>
      )
    } else {
      link = (
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/blog/`}
        >
          <Header />
        </Link>
      )
    }
    return (
      <StaticQuery
        query={layoutQuery}
        render={data => {
          const { social } = data.site.siteMetadata;
          return (
            <Wrapper>
              <div
                style={{
                  marginLeft: `auto`,
                  marginRight: `auto`,
                  maxWidth: rhythm(24),
                  padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
                }}
              >
                <header>{link}</header>
                <main>{children}</main>
              </div>
              <Footer>
                © {new Date().getFullYear()}, Coding Jewels Blog
                <p>
                  <a style={{ boxShadow: 'none' }}href={`https://facebook.com/${social.facebook}`}>
                    <img style={{ margin: 0, height: '1.5em', width: '1.5em' }} src={FacebookLogo} alt="Facebook Logo" />
                  </a>
                  {` `}
                  <a style={{ boxShadow: 'none' }}href={`https://instagram.com/${social.instagram}`}>
                    <img style={{ margin: 0, height: '1.5em', width: '1.5em' }} src={InstagramLogo} alt="Instagram Logo" />
                  </a>
                  {` `}
                  <a style={{ boxShadow: 'none' }}href={`https://linkedin.com/${social.linkedIn}`}>
                   <img style={{ margin: 0, height: '1.5em', width: '1.5em' }} src={LinkedInLogo} alt="LinkedIn Logo" />
                  </a>
                  {` `}
                  <a style={{ boxShadow: 'none' }}href={`https://twitter.com/${social.twitter}`}>
                    <img style={{ margin: 0, height: '1.5em', width: '1.5em' }} src={TwitterLogo} alt="Twitter Logo" />
                  </a>
                  {` `}
                  <a style={{ boxShadow: 'none' }}style={{ boxShadow: 'none' }} href={`https://youtube.com/${social.youTube}`}>
                    <img style={{ margin: 0, height: '1.5em', width: '1.5em' }} src={YouTubeLogo} alt="YouTube Logo" />
                  </a>
                  {` `}
                  <a style={{ boxShadow: 'none' }}style={{ boxShadow: 'none' }} href={`https://codepen.io/${social.codepen}`}>
                    <img style={{ margin: 0, height: '1.5em', width: '1.5em' }} src={CodepenLogo} alt="Codepen Logo" />
                  </a>
                </p>
              </Footer>
            </Wrapper>
          )
        }}
      />
    )
  }
}

const layoutQuery = graphql`
  query {
    avatar: file(absolutePath: { regex: "/coding-jewels-std-logo.png/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          facebook,
          instagram,
          linkedIn,
          twitter,
          youTube
        }
      }
    }
  }
`

const Wrapper = styled.div`
  min-height: 100vh;
`

const Footer = styled.footer`
  text-align: center;
  margin: 24px;
`

export default Layout
