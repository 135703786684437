import React from 'react'

import VerticalLogo from "../../content/assets/vertical-transparent.png"

const Header = () => {
    return (
        <header>
            <img style={{ margin: 0, height: '35%', width: '35%' }} src={VerticalLogo} alt="Coding Jewels Logo" />
        </header>
    )
}

export default Header